
import { defineComponent, onMounted, ref, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { hideModal } from "@/core/helpers/dom";

import DaftarPSP from "@/components/1crm/siswa/daftar-psp.vue";

import moment from "moment";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { Modal } from "bootstrap";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    DaftarPSP,
    Loading,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {

      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      migrasiModalRef: ref<null | HTMLElement>(null),
      disableButton: false,
      tableData: [],
      listSekolah: [],
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      title: "",
      type: "",
      email: "",
      status: "",

      detail: {
        createTime: "",
        email: "",
        message: "",
        status: "",
        title: "",
        type: "",
      },

    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {

      this.isLoading = true;

      const toast = useToast();

      let search2 = "";
      if (this.title) {
        search2 = "title=" + this.title + "&";
      } else {
        search2;
      }

      let search3 = "";
      if (this.type) {
        search3 = "type=" + this.type + "&";
      } else {
        search3;
      }

      let search4 = "";
      if (this.email) {
        search4 = "email=" + this.email + "&";
      } else {
        search4;
      }

      let search5 = "";
      if (this.status) {
        search5 = "status=" + this.status + "&";
      } else {
        search5;
      }


      ApiService.getWithoutSlug(
        "crmv2/main_notification/notification/office/all?" +
        "companyId=" +
        JSON.parse(localStorage.getItem("user_account")!).schoolId + "&" +
        search2 +
        search3 +
        search4 +
        search5 +
        "&page=" +
        page +
        "&size=" +
        size +
        "&sort=createTime&dir=-1"
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;

          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;

          if (response.status == 401) {
            toast.error(response.data.detail);
            this.$router.push('/sign-in');
            this.isLoading = false;
          } else {
            toast.error(response.data.detail);
            this.isLoading = false;
          }
        });
    },

    dateTime(value) {
      return moment(value).format("DD/MM/YYYY");
    },

    dateTime2(value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    },

    detailNotif(index, item) {

      this.isLoading = true;
      const toast = useToast();

      ApiService.getWithoutSlug(
        "crmv2/main_notification/notification/office/" + item._id + "/read"
      )
        .then(({ data }) => {
          this.detail.createTime = data.createTime;
          this.detail.email = data.email;
          this.detail.message = data.message
          this.detail.status = data.status
          this.detail.title = data.title
          this.detail.type = data.type.join(', ')

          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;

          if (response.status == 401) {
            toast.error(response.data.detail);
            this.$router.push('/sign-in');
            this.isLoading = false;
          } else {
            toast.error(response.data.detail);
            this.isLoading = false;
          }
        });

      const modal = new Modal(document.getElementById("kt_modal_notif"));
      modal.show();
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
  },
});
