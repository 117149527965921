
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter, useRoute } from "vue-router";

import GetNotifikasi from "@/components/1crm/1sekolah/notification/notification.vue";

export default defineComponent({
  name: "Notifikasi",
  components: {
    Field,
    GetNotifikasi,
  },
  props: {
    widgetClasses: String,
  },

  beforeMount() {
    const store = useStore();
    store.dispatch(Actions.VERIFY_AUTH_NOTIFIKASI);
    setCurrentPageBreadcrumbs("Notifikasi", ["Data"]);
  },

});
